import React from 'react';

const Ourcompany = () => {
      return (
  
        <div id="Content">
          <div className="content_wrapper clearfix">
            <div className="sections_group">
              <div className="entry-content">
                <div className="section mcb-section full-screen  bg-cover" style={{paddingTop: '0px', paddingBottom: '0px', backgroundColor: ''}} data-parallax="3d"><img className="mfn-parallax" src="assets/images/erp_company_bg.jpg" style={{opacity: 0}} />
                  <div className="section_wrapper mcb-section-inner">
                    <div className="wrap mcb-wrap one-second erp-shadow  valign-top clearfix" style={{padding: '30px 5% 10px', backgroundColor: '#fff'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column two-fifth column_image">
                          <div className="image_frame image_item no_link scale-with-grid no_border" style={{marginTop: '-75px', marginBottom: '-20px'}}>
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp_company_logo.png" />
                            </div>
                          </div>
                        </div>
                        <div className="column mcb-column one column_column">
                          <div className="column_attr clearfix" style={{padding: '0 1%'}}>
                            <h2>Ut ultricies imperdiet sodales. Lorem ipsum dolor mit samet.</h2>
                            <hr className="no_line" style={{margin: '0 auto 10px'}} />
                            <p>
                              Aliquam fringilla aliquam ex sit amet elementum. Proin bibendum sollicitudin feugiat.Duis dignissim mi ut laoreet mollis.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section mcb-section" style={{paddingTop: '75px', paddingBottom: '0px'}}>
                  <div className="section_wrapper mcb-section-inner">
                    <div className="wrap mcb-wrap one valign-top clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column three-fourth column_column">
                          <div className="column_attr clearfix">
                            <h1>Lorem ipsum dolor
                              <br /> mit samet</h1>
                            <hr className="no_line" style={{margin: '0 auto 20px'}} />
                            <p>
                              Aliquam ac dui vel dui vulputate consectetur. Mauris accumsan, massa non consectetur condimentum, diam arcu tristique nibh, nec egestas diam elit at nulla. Suspendisse potenti. In non lacinia risus, ac tempor ipsum. Phasellus venenatis leo eu semper varius.
                              Maecenas sit amet molestie leo.
                            </p>
                            <hr className="no_line" style={{margin: '0 auto 20px'}} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-second valign-top clearfix" style={{padding: '0 1%'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_image">
                          <div className="image_frame image_item no_link scale-with-grid no_border">
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp_company_worker.jpg" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-second valign-top clearfix" style={{padding: '0 2%'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_column">
                          <div className="column_attr clearfix">
                            <h2>Lorem ipsum dolor sit ametum consectetur adipisicing</h2>
                          </div>
                        </div>
                        <div className="column mcb-column two-fifth column_chart">
                          <div className="chart_box">
                            <div className="chart" data-percent={25} data-bar-color="#79ab00" data-line-width={7}>
                              <div className="num">
                                25%
                              </div>
                            </div>
                            <p>
                              <big>Lorem ipsum</big>
                            </p>
                          </div>
                        </div>
                        <div className="column mcb-column three-fifth column_column">
                          <div className="column_attr clearfix" style={{padding: '0 2%'}}>
                            <h4>Mauris rhoncus orci in imperdiet placerat.</h4>
                            <p>
                              Vestibulum euismod nisl suscipit ligula volutpat, a feugiat urna maximus. Cras massa nibh, tincidunt ut eros a, vulputate consequat odio. Vestibulum vehicula tempor nulla, sed hendrerit urna interdum in.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one valign-top clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one-second column_column">
                          <div className="column_attr clearfix">
                            <hr className="no_line" style={{margin: '0 auto 50px'}} />
                            <h2>Sed ultrices nisl velit, eu ornare est ullamcorper.</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section mcb-section equal-height-wrap" style={{paddingTop: '50px', paddingBottom: '50px'}}>
                  <div className="section_wrapper mcb-section-inner">
                    <div className="wrap mcb-wrap one-fourth valign-middle clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_image">
                          <div className="image_frame image_item no_link scale-with-grid no_border">
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp-home-features-icon1.png" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-fourth valign-middle clearfix" style={{padding: '0 30px 0 0'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_column">
                          <div className="column_attr clearfix">
                            <h3>Integration</h3>
                            <p>
                              Ut ultricies imperdiet sodales. Aliquam fringilla aliquam ex sit amet elementum. Proin bibendum sollicitudin feugiat.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-fourth valign-middle clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_image">
                          <div className="image_frame image_item no_link scale-with-grid no_border">
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp-home-features-icon2.png" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-fourth valign-middle clearfix" style={{padding: '0 30px 0 0'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_column">
                          <div className="column_attr clearfix">
                            <h3>Free training</h3>
                            <p>
                              Ut ultricies imperdiet sodales. Aliquam fringilla aliquam ex sit amet elementum. Proin bibendum sollicitudin feugiat.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-fourth valign-middle clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_image">
                          <div className="image_frame image_item no_link scale-with-grid no_border">
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp-home-features-icon3.png" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-fourth valign-middle clearfix" style={{padding: '0 30px 0 0'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_column">
                          <div className="column_attr clearfix">
                            <h3>Free updates</h3>
                            <p>
                              Ut ultricies imperdiet sodales. Aliquam fringilla aliquam ex sit amet elementum. Proin bibendum sollicitudin feugiat.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-fourth valign-middle clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_image">
                          <div className="image_frame image_item no_link scale-with-grid no_border">
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp-home-features-icon4.png" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-fourth valign-middle clearfix" style={{padding: '0 30px 0 0'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_column">
                          <div className="column_attr clearfix">
                            <h3>Cloud based</h3>
                            <p>
                              Ut ultricies imperdiet sodales. Aliquam fringilla aliquam ex sit amet elementum. Proin bibendum sollicitudin feugiat.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section mcb-section" style={{paddingTop: '50px', paddingBottom: '100px'}}>
                  <div className="section_wrapper mcb-section-inner">
                    <div className="wrap mcb-wrap one-second valign-top clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column five-sixth column_image">
                          <div className="image_frame image_item no_link scale-with-grid no_border">
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp-home-phoen-icon.png" />
                            </div>
                          </div>
                        </div>
                        <div className="column mcb-column one column_column">
                          <div className="column_attr clearfix">
                            <h6>CONTACT WITH US</h6>
                            <h2>Order free training
                              <br /> and presentation</h2>
                            <hr className="no_line" style={{margin: '0 auto 20px'}} />
                            <h4>+22 94 485 223</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-second valign-top clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column five-sixth column_image">
                          <div className="image_frame image_item no_link scale-with-grid no_border">
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp-cloud-icon.png" />
                            </div>
                          </div>
                        </div>
                        <div className="column mcb-column one column_column">
                          <div className="column_attr clearfix">
                            <h6>DOWNLOAD</h6>
                            <h2>Start free 30day trial
                              <br /> with 3 example modules</h2>
                            <hr className="no_line" style={{margin: '0 auto 20px'}} />
                            <div className="image_frame image_item scale-with-grid no_border hover-disable">
                              <div className="image_wrapper">
                                <a href="modules.html">
                                  &lt; div class="mask"&gt;</a></div><a href="modules.html">
                                <img className="scale-with-grid" src="assets/images/erp-slider-app-store-button.png" />
                              </a>
                              <div className="image_links">
                                <a href="modules.html" className="link"><i className="icon-link" /></a>
                              </div>
                            </div>
                          </div>
                          <hr className="no_line" style={{margin: '0 auto 5px'}} />
                          <p style={{opacity: '.5'}}>
                            iOS 9.3+
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };

export default Ourcompany;