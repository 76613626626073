import React from 'react';

const Home = () => {
      return (
  
        <div id="Content">
          <div className="content_wrapper clearfix">
            <div className="sections_group">
              <div className="entry-content">
                <div className="section mcb-section no-margin-v bg-cover" style={{paddingTop: '125px', paddingBottom: '125px', backgroundImage: 'url(assets/images/erp-slider-bg.jpg)', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}>
                  <div className="section_wrapper mcb-section-inner">
                    <div className="wrap mcb-wrap three-fifth valign-top clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_column">
                          <div className="column_attr clearfix">
                            <h1>Advanced and easy
                              <br /> to implement resource
                              <br /> planning system</h1>
                            <hr className="no_line" style={{margin: '0 auto 50px'}} />
                            <p style={{color: '#03056b'}} className="big">
                              <b>Version 10.3 with 3 modules
                                <br />
                                30-day free trial</b>
                            </p>
                            <hr className="no_line" style={{margin: '0 auto 10px'}} />
                          </div>
                        </div>
                        <div className="column mcb-column one-second column_column">
                          <div className="column_attr clearfix">
                            <div className="image_frame image_item scale-with-grid no_border hover-disable">
                              <div className="image_wrapper">
                                <a href="details.html">
                                  <div className="mask" />
                                  <img className="scale-with-grid" src="assets/images/erp-Download-trial-version.png" />
                                </a>
                                <div className="image_links">
                                  <a href="details.html" className="link"><i className="icon-link" /></a>
                                </div>
                              </div>
                            </div>
                            <hr className="no_line" style={{margin: '0 auto 5px'}} />
                            <p style={{opacity: '.5'}}>
                              MacOs 10.3+
                            </p>
                          </div>
                        </div>
                        <div className="column mcb-column one-third column_column">
                          <div className="column_attr clearfix">
                            <div className="image_frame image_item scale-with-grid no_border hover-disable">
                              <div className="image_wrapper">
                                <a href="details.html">
                                  <div className="mask" />
                                  <img className="scale-with-grid" src="assets/images/erp-slider-app-store-button.png" />
                                </a>
                                <div className="image_links">
                                  <a href="details.html" className="link"><i className="icon-link" /></a>
                                </div>
                              </div>
                            </div>
                            <hr className="no_line" style={{margin: '0 auto 5px'}} />
                            <p style={{opacity: '.5'}}>
                              iOS 9.3+
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section mcb-section" style={{paddingTop: '125px', paddingBottom: '50px'}}>
                  <div className="section_wrapper mcb-section-inner">
                    <div className="wrap mcb-wrap one valign-top clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_column">
                          <div className="column_attr clearfix align_center">
                            <h6>BUY LICENSES</h6>
                            <h2>Core module with 7 additional modules
                              <br /> are ready for your needs</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section mcb-section" style={{paddingTop: '0px', paddingBottom: '100px'}}>
                  <div className="section_wrapper mcb-section-inner">
                    <div className="wrap mcb-wrap one-fourth valign-top clearfix" style={{padding: '0 1%'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_hover_color erp-shadow">
                          <div className="hover_color align_left" style={{background: '#fcfcfc', borderColor: '#4d70d4'}} onTouchStart="this.classList.toggle('hover');">
                            <div className="hover_color_bg" style={{background: '#fff', borderColor: '#e4e7f1', borderWidth: '1px'}}>
                              <a href="modules.html">
                                <div className="hover_color_wrapper" style={{padding: '20px 30px'}}>
                                  <div className="image_frame image_item no_link scale-with-grid aligncenter no_border">
                                    <div className="image_wrapper">
                                      <img className="scale-with-grid" src="assets/images/erp-offer-icon1.png" />
                                    </div>
                                  </div>
                                  <hr className="no_line" style={{margin: '0 auto 20px'}} />
                                  <h4 className="themecolor" style={{marginBottom: '5px'}}>FREE</h4>
                                  <h3>Core module</h3>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-fourth valign-top clearfix" style={{padding: '0 1%'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_hover_color erp-shadow">
                          <div className="hover_color align_left" style={{background: '#fcfcfc', borderColor: '#4d70d4'}} onTouchStart="this.classList.toggle('hover');">
                            <div className="hover_color_bg" style={{background: '#fff', borderColor: '#e4e7f1', borderWidth: '1px'}}>
                              <a href="modules.html">
                                <div className="hover_color_wrapper" style={{padding: '20px 30px'}}>
                                  <div className="image_frame image_item no_link scale-with-grid aligncenter no_border">
                                    <div className="image_wrapper">
                                      <img className="scale-with-grid" src="assets/images/erp-offer-icon2.png" />
                                    </div>
                                  </div>
                                  <hr className="no_line" style={{margin: '0 auto 20px'}} />
                                  <h4 className="themecolor" style={{marginBottom: '5px'}}>$95+</h4>
                                  <h3>HR</h3>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-fourth valign-top clearfix" style={{padding: '0 1%'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_hover_color erp-shadow">
                          <div className="hover_color align_left" style={{background: '#fcfcfc', borderColor: '#4d70d4'}} onTouchStart="this.classList.toggle('hover');">
                            <div className="hover_color_bg" style={{background: '#ffffff', borderColor: '#e4e7f1', borderWidth: '1px'}}>
                              <a href="modules.html">
                                <div className="hover_color_wrapper" style={{padding: '20px 30px'}}>
                                  <div className="image_frame image_item no_link scale-with-grid aligncenter no_border">
                                    <div className="image_wrapper">
                                      <img className="scale-with-grid" src="assets/images/erp-offer-icon3.png" />
                                    </div>
                                  </div>
                                  <hr className="no_line" style={{margin: '0 auto 20px'}} />
                                  <h4 className="themecolor" style={{marginBottom: '5px'}}>$120+</h4>
                                  <h3>Accounting</h3>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-fourth valign-top clearfix" style={{padding: '0 1%'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_hover_color erp-shadow">
                          <div className="hover_color align_left" style={{background: '#fcfcfc', borderColor: '#4d70d4'}} onTouchStart="this.classList.toggle('hover');">
                            <div className="hover_color_bg" style={{background: '#fff', borderColor: '#e4e7f1', borderWidth: '1px'}}>
                              <a href="modules.html">
                                <div className="hover_color_wrapper" style={{padding: '20px 30px'}}>
                                  <div className="image_frame image_item no_link scale-with-grid aligncenter no_border">
                                    <div className="image_wrapper">
                                      <img className="scale-with-grid" src="assets/images/erp-offer-icon4.png" />
                                    </div>
                                  </div>
                                  <hr className="no_line" style={{margin: '0 auto 20px'}} />
                                  <h4 className="themecolor" style={{marginBottom: '5px'}}>$530+</h4>
                                  <h3>Production</h3>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap divider valign-top clearfix">
                      <div className="mcb-wrap-inner" />
                    </div>
                    <div className="wrap mcb-wrap one-fourth valign-top clearfix" style={{padding: '0 1%'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_hover_color erp-shadow">
                          <div className="hover_color align_left" style={{background: '#fcfcfc', borderColor: '#4d70d4'}} onTouchStart="this.classList.toggle('hover');">
                            <div className="hover_color_bg" style={{background: '#fff', borderColor: '#e4e7f1', borderWidth: '1px'}}>
                              <a href="modules.html">
                                <div className="hover_color_wrapper" style={{padding: '20px 30px'}}>
                                  <div className="image_frame image_item no_link scale-with-grid aligncenter no_border">
                                    <div className="image_wrapper">
                                      <img className="scale-with-grid" src="assets/images/erp-offer-icon5.png" />
                                    </div>
                                  </div>
                                  <hr className="no_line" style={{margin: '0 auto 20px'}} />
                                  <h4 className="themecolor" style={{marginBottom: '5px'}}>$599+</h4>
                                  <h3>Marketing</h3>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-fourth valign-top clearfix" style={{padding: '0 1%'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_hover_color erp-shadow">
                          <div className="hover_color align_left" style={{background: '#fcfcfc', borderColor: '#4d70d4'}} onTouchStart="this.classList.toggle('hover');">
                            <div className="hover_color_bg" style={{background: '#fff', borderColor: '#e4e7f1', borderWidth: '1px'}}>
                              <a href="modules.html">
                                <div className="hover_color_wrapper" style={{padding: '20px 30px'}}>
                                  <div className="image_frame image_item no_link scale-with-grid aligncenter no_border">
                                    <div className="image_wrapper">
                                      <img className="scale-with-grid" src="assets/images/erp-offer-icon6.png" />
                                    </div>
                                  </div>
                                  <hr className="no_line" style={{margin: '0 auto 20px'}} />
                                  <h4 className="themecolor" style={{marginBottom: '5px'}}>$145</h4>
                                  <h3>Inventory</h3>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-fourth valign-top clearfix" style={{padding: '0 1%'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_hover_color erp-shadow">
                          <div className="hover_color align_left" style={{background: '#fcfcfc', borderColor: '#4d70d4'}} onTouchStart="this.classList.toggle('hover');">
                            <div className="hover_color_bg" style={{background: '#fff', borderColor: '#e4e7f1', borderWidth: '1px'}}>
                              <a href="modules.html">
                                <div className="hover_color_wrapper" style={{padding: '20px 30px'}}>
                                  <div className="image_frame image_item no_link scale-with-grid aligncenter no_border">
                                    <div className="image_wrapper">
                                      <img className="scale-with-grid" src="assets/images/erp-offer-icon7.png" />
                                    </div>
                                  </div>
                                  <hr className="no_line" style={{margin: '0 auto 20px'}} />
                                  <h4 className="themecolor" style={{marginBottom: '5px'}}>$99</h4>
                                  <h3>CRM</h3>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-fourth valign-top clearfix" style={{padding: '0 1%'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_hover_color erp-shadow">
                          <div className="hover_color align_left" style={{background: '#fcfcfc', borderColor: '#4d70d4'}} onTouchStart="this.classList.toggle('hover');">
                            <div className="hover_color_bg" style={{background: '#fff', borderColor: '#e4e7f1', borderWidth: '1px'}}>
                              <a href="modules.html">
                                <div className="hover_color_wrapper" style={{padding: '20px 30px'}}>
                                  <div className="image_frame image_item no_link scale-with-grid aligncenter no_border">
                                    <div className="image_wrapper">
                                      <img className="scale-with-grid" src="assets/images/erp-offer-icon8.png" />
                                    </div>
                                  </div>
                                  <hr className="no_line" style={{margin: '0 auto 20px'}} />
                                  <h4 className="themecolor" style={{marginBottom: '5px'}}>Know</h4>
                                  <h3>More</h3>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section mcb-section equal-height-wrap" style={{paddingTop: '0px', paddingBottom: '0px'}}>
                  <div className="section_wrapper mcb-section-inner">
                    <div className="wrap mcb-wrap one-second valign-middle clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column five-sixth column_column">
                          <div className="column_attr clearfix align_left">
                            <h6>CLOUD BASED</h6>
                            <h2>Easy to learn and
                              <br /> extremely powerful</h2>
                            <hr className="no_line" style={{margin: '0 auto 30px'}} />
                            <p>
                              Duis dignissim mi ut laoreet mollis. Nunc id tellus finibus, eleifend mi vel, maximus justo. Maecenas mi tortor, pellentesque a aliquam ut, fringilla eleifend lectus. Maecenas ultrices tellus sit amet sem placerat tempor. Maecenas eget arcu venenatis,
                              sagittis felis sit amet, dictum nisl.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-second valign-middle clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_slider">
                          <div className="content_slider flat hide-arrows">
                            <ul className="content_slider_ul">
                              <li className="content_slider_li_1">
                                <img src="assets/images/erp-home-slide3.jpg" className="scale-with-grid wp-post-image" />
                              </li>
                              <li className="content_slider_li_2">
                                <img src="assets/images/erp-home-slide2.jpg" className="scale-with-grid wp-post-image" />
                              </li>
                              <li className="content_slider_li_3">
                                <img src="assets/images/erp-home-slide1.jpg" className="scale-with-grid wp-post-image" />
                              </li>
                            </ul>
                            <div className="slider_pager slider_pagination" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section mcb-section" style={{paddingTop: '100px', paddingBottom: '0px'}}>
                  <div className="section_wrapper mcb-section-inner">
                    <div className="wrap mcb-wrap one-second valign-middle clearfix" style={{padding: '0 2%'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_image">
                          <div className="image_frame image_item no_link scale-with-grid no_border">
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp-home-smartphone.jpg" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-second valign-middle clearfix" style={{padding: '0 2%'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_column">
                          <div className="column_attr clearfix align_left">
                            <hr className="no_line" style={{margin: '0 auto 40px'}} />
                            <h6>USER FRIENDLY</h6>
                            <h2>Your business in your
                              <br /> hands wherever you are</h2>
                            <hr className="no_line" style={{margin: '0 auto 30px'}} />
                            <p>
                              Duis dignissim mi ut laoreet mollis. Nunc id tellus finibus, eleifend mi vel, maximus justo. Maecenas mi tortor, pellentesque a aliquam ut, fringilla eleifend lectus. Maecenas ultrices tellus sit amet sem placerat tempor. Maecenas eget arcu venenatis,
                              sagittis felis sit amet, dictum nisl.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one valign-top clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_column column-margin-20px">
                          <div className="column_attr clearfix align_center">
                            <hr className="no_line" style={{margin: '0 auto 75px'}} />
                            <h6>FEATURES</h6>
                            <h2>All the features
                              <br /> for micro, small and big companies</h2>
                          </div>
                        </div>
                        <div className="column mcb-column one-second column_column">
                          <div className="column_attr clearfix align_right mobile_align_center">
                            <a className="button button_size_3 button_js" href="modules.html" target="_blank" style={{backgroundColor: '#eeeef6', color: '#000'}}><span className="button_label">View all features</span></a>
                          </div>
                        </div>
                        <div className="column mcb-column one-second column_column">
                          <div className="column_attr clearfix align_left mobile_align_center">
                            <a className="button button_size_3 button_js" href="modules.html" target="_blank" style={{backgroundColor: '#5dab00', color: '#fff'}}><span className="button_label">Contact with us</span></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section mcb-section equal-height-wrap" style={{paddingTop: '50px', paddingBottom: '50px'}}>
                  <div className="section_wrapper mcb-section-inner">
                    <div className="wrap mcb-wrap one-fourth valign-middle clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_image">
                          <div className="image_frame image_item no_link scale-with-grid no_border">
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp-home-features-icon1.png" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-fourth valign-middle clearfix" style={{padding: '0 30px 0 0'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_column">
                          <div className="column_attr clearfix">
                            <h3>Integration</h3>
                            <p>
                              Ut ultricies imperdiet sodales. Aliquam fringilla aliquam ex sit amet elementum. Proin bibendum sollicitudin feugiat.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-fourth valign-middle clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_image">
                          <div className="image_frame image_item no_link scale-with-grid no_border">
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp-home-features-icon2.png" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-fourth valign-middle clearfix" style={{padding: '0 30px 0 0'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_column">
                          <div className="column_attr clearfix">
                            <h3>Free training</h3>
                            <p>
                              Ut ultricies imperdiet sodales. Aliquam fringilla aliquam ex sit amet elementum. Proin bibendum sollicitudin feugiat.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-fourth valign-middle clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_image">
                          <div className="image_frame image_item no_link scale-with-grid no_border">
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp-home-features-icon3.png" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-fourth valign-middle clearfix" style={{padding: '0 30px 0 0'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_column">
                          <div className="column_attr clearfix">
                            <h3>Free updates</h3>
                            <p>
                              Ut ultricies imperdiet sodales. Aliquam fringilla aliquam ex sit amet elementum. Proin bibendum sollicitudin feugiat.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-fourth valign-middle clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_image">
                          <div className="image_frame image_item no_link scale-with-grid no_border">
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp-home-features-icon4.png" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-fourth valign-middle clearfix" style={{padding: '0 30px 0 0'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_column">
                          <div className="column_attr clearfix">
                            <h3>Cloud based</h3>
                            <p>
                              Ut ultricies imperdiet sodales. Aliquam fringilla aliquam ex sit amet elementum. Proin bibendum sollicitudin feugiat.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section mcb-section no-margin-v dark equal-height-wrap" style={{paddingTop: '125px', paddingBottom: '0px', backgroundColor: '#0f0338'}}>
                  <div className="section_wrapper mcb-section-inner">
                    <div className="wrap mcb-wrap two-fifth valign-middle clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_column">
                          <div className="column_attr clearfix">
                            <h2>Create inteligent
                              <br /> automations
                              <br /> with ease</h2>
                          </div>
                        </div>
                        <div className="column mcb-column five-sixth column_image">
                          <div className="image_frame image_item scale-with-grid no_border hover-disable" style={{marginTop: '30px', marginBottom: '30px'}}>
                            <div className="image_wrapper">
                              <a href="https://www.youtube.com/watch?v=eRmZ_sNf2mE" rel="prettyphoto">
                                <div className="mask" />
                                <img className="scale-with-grid" src="assets/images/erp-home-play-button.png" />
                              </a>
                              <div className="image_links">
                                <a href="https://www.youtube.com/watch?v=eRmZ_sNf2mE" className="zoom" rel="prettyphoto"><i className="icon-search" /></a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="column mcb-column four-fifth column_column">
                          <div className="column_attr clearfix">
                            <p>
                              Duis dignissim mi ut laoreet mollis. Nunc id tellus finibus, eleifend mi vel, maximus justo. Maecenas mi tortor, pellentesque a aliquam ut, fringilla eleifend lectus.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap three-fifth valign-bottom clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_image">
                          <div className="image_frame image_item no_link scale-with-grid no_border">
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp-home-erp-panel.jpg" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section mcb-section no-margin-v" style={{paddingTop: '125px', paddingBottom: '125px'}}>
                  <div className="section_wrapper mcb-section-inner">
                    <div className="wrap mcb-wrap one valign-top clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_column">
                          <div className="column_attr clearfix align_center">
                            <h6>OUR CLIENTS</h6>
                            <h2>They already have trusted BeERP</h2>
                            <hr className="no_line" style={{margin: '0 auto 40px'}} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-third valign-top clearfix" style={{padding: '20px 2%'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column five-sixth column_image">
                          <div className="image_frame image_item no_link scale-with-grid no_border">
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp-logo-vove.png" />
                            </div>
                          </div>
                        </div>
                        <div className="column mcb-column five-sixth column_column">
                          <div className="column_attr clearfix">
                            <p>
                              Vivamus in diam turpis. In condimentum maximus tristique. Maecenas non laoreet odio. Fusce lobortis porttitor purus, vel vestibulum libero pharetra vel.
                            </p>
                            <hr className="no_line" style={{margin: '0 auto 20px'}} />
                          </div>
                        </div>
                        <div className="column mcb-column one-fifth column_image">
                          <div className="image_frame image_item no_link scale-with-grid no_border">
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp-client1.png" />
                            </div>
                          </div>
                        </div>
                        <div className="column mcb-column three-fourth column_column">
                          <div className="column_attr clearfix">
                            <h4 style={{marginBottom: 0}}>Mark Spencer</h4>
                            <p>
                              Lorem ipsum dolor
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-third valign-top clearfix" style={{padding: '20px 2%'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column five-sixth column_image">
                          <div className="image_frame image_item no_link scale-with-grid no_border">
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp-logo-II.png" />
                            </div>
                          </div>
                        </div>
                        <div className="column mcb-column five-sixth column_column">
                          <div className="column_attr clearfix">
                            <p>
                              Vivamus in diam turpis. In condimentum maximus tristique. Maecenas non laoreet odio. Fusce lobortis porttitor purus, vel vestibulum libero pharetra vel.
                            </p>
                            <hr className="no_line" style={{margin: '0 auto 20px'}} />
                          </div>
                        </div>
                        <div className="column mcb-column one-fifth column_image">
                          <div className="image_frame image_item no_link scale-with-grid no_border">
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp-client3.png" />
                            </div>
                          </div>
                        </div>
                        <div className="column mcb-column three-fourth column_column">
                          <div className="column_attr clearfix">
                            <h4 style={{marginBottom: 0}}>Mark Spencer</h4>
                            <p>
                              Lorem ipsum dolor
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-third valign-top clearfix" style={{padding: '20px 2%'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column five-sixth column_image">
                          <div className="image_frame image_item no_link scale-with-grid no_border">
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp-log-green.png" />
                            </div>
                          </div>
                        </div>
                        <div className="column mcb-column five-sixth column_column">
                          <div className="column_attr clearfix">
                            <p>
                              Vivamus in diam turpis. In condimentum maximus tristique. Maecenas non laoreet odio. Fusce lobortis porttitor purus, vel vestibulum libero pharetra vel.
                            </p>
                            <hr className="no_line" style={{margin: '0 auto 20px'}} />
                          </div>
                        </div>
                        <div className="column mcb-column one-fifth column_image">
                          <div className="image_frame image_item no_link scale-with-grid no_border">
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp-client2.png" />
                            </div>
                          </div>
                        </div>
                        <div className="column mcb-column three-fourth column_column">
                          <div className="column_attr clearfix">
                            <h4 style={{marginBottom: 0}}>Mark Spencer</h4>
                            <p>
                              Lorem ipsum dolor
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section mcb-section" style={{paddingTop: '50px', paddingBottom: '75px'}}>
                  <div className="section_wrapper mcb-section-inner">
                    <div className="wrap mcb-wrap one-second valign-top clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column five-sixth column_image">
                          <div className="image_frame image_item no_link scale-with-grid no_border">
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp-home-phoen-icon.png" />
                            </div>
                          </div>
                        </div>
                        <div className="column mcb-column one column_column">
                          <div className="column_attr clearfix">
                            <h6>CONTACT WITH US</h6>
                            <h2>Order free training
                              <br /> and presentation</h2>
                            <hr className="no_line" style={{margin: '0 auto 20px'}} />
                            <h4>+22 94 485 223</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-second valign-top clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column five-sixth column_image">
                          <div className="image_frame image_item no_link scale-with-grid no_border">
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp-cloud-icon.png" />
                            </div>
                          </div>
                        </div>
                        <div className="column mcb-column one column_column">
                          <div className="column_attr clearfix">
                            <h6>DOWNLOAD</h6>
                            <h2>Start free 30day trial
                              <br /> with 3 example modules</h2>
                            <hr className="no_line" style={{margin: '0 auto 20px'}} />
                            <div className="image_frame image_item scale-with-grid no_border hover-disable">
                              <div className="image_wrapper">
                                <a href="modules.html">
                                  <div className="mask" />
                                  <img className="scale-with-grid" src="assets/images/erp-slider-app-store-button.png" />
                                </a>
                                <div className="image_links">
                                  <a href="modules.html" className="link"><i className="icon-link" /></a>
                                </div>
                              </div>
                            </div>
                            <hr className="no_line" style={{margin: '0 auto 5px'}} />
                            <p style={{opacity: '.5'}}>
                              iOS 9.3+
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };

    export default Home;