import Header from "./pages/Header";
import Home from "./pages/Home";
import Modules from "./pages/Modules";
import Ourcompany from "./pages/Ourcompany";
import Details from "./pages/Details";
import Documentation from "./pages/Documentation";
import Footer from "./pages/Footer";
import Slide from "./pages/Slide";
import Error from "./pages/Error";
import React from "react";
import { Route, Switch } from 'react-router-dom';
import './App.css';

function App() {
  return (
    <div id="Wrapper">
      <Header /> 
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/modules" component={Modules} />
        <Route exact path="/ourcompany" component={Ourcompany} />
        <Route exact path="/details" component={Details} />
        <Route exact path="/documentation" component={Documentation} />       
        <Route component={Error} />
      </Switch>      
      <Footer />
      <Slide />
    </div>
          );
}

export default App;
