import React from 'react';
const Details = () => {
      return (
  
        <div id="Content">
          <div className="content_wrapper clearfix">
            <div className="sections_group">
              <div className="entry-content">
                <div className="section mcb-section equal-height-wrap bg-cover" style={{paddingTop: '125px', paddingBottom: '125px', backgroundImage: 'url(assets/images/erp_details_bg.jpg)', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}>
                  <div className="section_wrapper mcb-section-inner">
                    <div className="wrap mcb-wrap one-second valign-middle clearfix" style={{padding: '0 2%'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_column">
                          <div className="column_attr clearfix">
                            <h1>Production
                              <br /> Module
                            </h1>
                            <hr className="no_line" style={{margin: '0 auto 20px'}} />
                            <p>
                              Vivamus in diam turpis. In condimentum maximus tristique. Maecenas non laoreet odio. Fusce lobortis porttitor purus, vel vestibulum libero pharetra vel. Pellentesque lorem augue, fermentum nec nibh et, fringilla sollicitudin orci.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-second valign-middle clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_image">
                          <div className="image_frame image_item no_link scale-with-grid aligncenter no_border">
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp_details_production_module.png" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section mcb-section" style={{paddingTop: '75px', paddingBottom: '0px'}}>
                  <div className="section_wrapper mcb-section-inner">
                    <div className="wrap mcb-wrap one valign-top clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one-sixth column_placeholder">
                          <div className="placeholder">
                            &nbsp;
                          </div>
                        </div>
                        <div className="column mcb-column two-third column_column">
                          <div className="column_attr clearfix">
                            <h2>Lorem ipsum dolor</h2>
                            <hr className="no_line" style={{margin: '0 auto 10px'}} />
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla mauris dolor, gravida a varius blandit, auctor eget purus. Phasellus scelerisque sapien sit amet mauris laoreet, eget scelerisque nunc cursus. Duis ultricies malesuada leo vel aliquet. Curabitur
                              rutrum porta dui eget mollis. Nullam lacinia dictum auctor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section mcb-section" style={{paddingTop: '50px', paddingBottom: '50px'}}>
                  <div className="section_wrapper mcb-section-inner">
                    <div className="wrap mcb-wrap one-fourth valign-top clearfix" style={{padding: '0 2%'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_icon_box">
                          <div className="icon_box icon_position_top no_border">
                            <div className="image_wrapper">
                              <img src="assets/images/erp_details_icon1.png" className="scale-with-grid" />
                            </div>
                            <div className="desc_wrapper">
                              <h3 className="title">Implement</h3>
                              <div className="desc">
                                Vivamus in diam turpis. In condimentum maximus tristique. Maecenas non laoreet odio.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-fourth valign-top clearfix" style={{padding: '0 2%'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_icon_box">
                          <div className="icon_box icon_position_top no_border">
                            <div className="image_wrapper">
                              <img src="assets/images/erp_details_icon2.png" className="scale-with-grid" />
                            </div>
                            <div className="desc_wrapper">
                              <h3 className="title">Cloud based</h3>
                              <div className="desc">
                                Vivamus in diam turpis. In condimentum maximus tristique. Maecenas non laoreet odio.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-fourth valign-top clearfix" style={{padding: '0 2%'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_icon_box">
                          <div className="icon_box icon_position_top no_border">
                            <div className="image_wrapper">
                              <img src="assets/images/erp_details_icon3.png" className="scale-with-grid" />
                            </div>
                            <div className="desc_wrapper">
                              <h3 className="title">Real time control</h3>
                              <div className="desc">
                                Vivamus in diam turpis. In condimentum maximus tristique. Maecenas non laoreet odio.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-fourth valign-top clearfix" style={{padding: '0 2%'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_icon_box">
                          <div className="icon_box icon_position_top no_border">
                            <div className="image_wrapper">
                              <img src="assets/images/erp_details_icon4.png" className="scale-with-grid" />
                            </div>
                            <div className="desc_wrapper">
                              <h3 className="title">Interface</h3>
                              <div className="desc">
                                Vivamus in diam turpis. In condimentum maximus tristique. Maecenas non laoreet odio.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section mcb-section" style={{paddingTop: '0px', paddingBottom: '0px'}}>
                  <div className="section_wrapper mcb-section-inner">
                    <div className="wrap mcb-wrap one valign-top clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one-sixth column_placeholder">
                          <div className="placeholder">
                            &nbsp;
                          </div>
                        </div>
                        <div className="column mcb-column two-third column_column">
                          <div className="column_attr clearfix">
                            <h2>Lorem ipsum dolor</h2>
                            <hr className="no_line" style={{margin: '0 auto 10px'}} />
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla mauris dolor, gravida a varius blandit, auctor eget purus. Phasellus scelerisque sapien sit amet mauris laoreet, eget scelerisque nunc cursus. Duis ultricies malesuada leo vel aliquet. Curabitur
                              rutrum porta dui eget mollis. Nullam lacinia dictum auctor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.
                            </p>
                          </div>
                        </div>
                        <div className="column mcb-column one column_image">
                          <div className="image_frame image_item no_link scale-with-grid no_border">
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp_details_nav.png" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section mcb-section equal-height-wrap" style={{paddingTop: '0px', paddingBottom: '0px'}}>
                  <div className="section_wrapper mcb-section-inner">
                    <div className="wrap mcb-wrap two-third valign-middle clearfix" style={{padding: '0 3%'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_image">
                          <div className="image_frame image_item no_link scale-with-grid no_border">
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp_details_graph.png" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-third valign-middle clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_column">
                          <div className="column_attr clearfix">
                            <h2>New graph</h2>
                            <hr className="no_line" style={{margin: '0 auto 10px'}} />
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla mauris dolor, gravida a varius blandit, auctor eget purus. Phasellus scelerisque sapien sit amet mauris laoreet, eget scelerisque nunc cursus. Duis ultricies malesuada leo vel aliquet.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section mcb-section equal-height-wrap" style={{paddingTop: '75px', paddingBottom: '50px'}}>
                  <div className="section_wrapper mcb-section-inner">
                    <div className="wrap mcb-wrap two-fifth valign-top clearfix" style={{padding: '0 3%'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_column">
                          <div className="column_attr clearfix">
                            <hr className="no_line" style={{margin: '0 auto 50px'}} />
                            <h2>List apis</h2>
                            <hr className="no_line" style={{margin: '0 auto 10px'}} />
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla mauris dolor, gravida a varius blandit, auctor eget purus. Phasellus scelerisque sapien sit amet mauris laoreet, eget scelerisque nunc cursus. Duis ultricies malesuada leo vel aliquet.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap three-fifth valign-middle clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_image">
                          <div className="image_frame image_item no_link scale-with-grid no_border">
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp_details_list.png" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section mcb-section" style={{paddingTop: '75px', paddingBottom: '100px'}}>
                  <div className="section_wrapper mcb-section-inner">
                    <div className="wrap mcb-wrap one-second valign-top clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column five-sixth column_image">
                          <div className="image_frame image_item no_link scale-with-grid no_border">
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp-home-phoen-icon.png" />
                            </div>
                          </div>
                        </div>
                        <div className="column mcb-column one column_column">
                          <div className="column_attr clearfix">
                            <h6>CONTACT WITH US</h6>
                            <h2>Order free training
                              <br /> and presentation</h2>
                            <hr className="no_line" style={{margin: '0 auto 20px'}} />
                            <h4>+22 94 485 223</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-second valign-top clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column five-sixth column_image">
                          <div className="image_frame image_item no_link scale-with-grid no_border">
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp-cloud-icon.png" />
                            </div>
                          </div>
                        </div>
                        <div className="column mcb-column one column_column">
                          <div className="column_attr clearfix">
                            <h6>DOWNLOAD</h6>
                            <h2>Start free 30day trial
                              <br /> with 3 example modules</h2>
                            <hr className="no_line" style={{margin: '0 auto 20px'}} />
                            <div className="image_frame image_item scale-with-grid no_border hover-disable">
                              <div className="image_wrapper">
                                <a href="modules.html">
                                  <div className="mask" />
                                  <img className="scale-with-grid" src="assets/images/erp-slider-app-store-button.png" />
                                </a>
                                <div className="image_links">
                                  <a href="modules.html" className="link"><i className="icon-link" /></a>
                                </div>
                              </div>
                            </div>
                            <hr className="no_line" style={{margin: '0 auto 5px'}} />
                            <p style={{opacity: '.5'}}>
                              iOS 9.3+
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };
    
    export default Details;
