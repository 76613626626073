import React from 'react';
 const Slide = () => {
     return <>
     
        <div id="Side_slide" className="right light" data-width={250}>
          <div className="close-wrapper">
            <a href="#" className="close"><i className="icon-cancel-fine" /></a>
          </div>
          <div className="extras">
            <div className="extras-wrapper">
              <a className="icon search" href="#"><i className="icon-search-fine" /></a>
            </div>
          </div>
          <div className="search-wrapper">
            <form id="side-form" method="get" action="#">
              <input type="text" className="field" name="s" placeholder="Enter your search" />
              <input type="submit" className="submit" defaultValue style={{display: 'none'}} />
              <a className="submit" href="#"><i className="icon-search-fine" /></a>
            </form>
          </div>
          <div className="menu_wrapper" />
        </div>
        <div id="body_overlay" />
      
 </>;
 };
 export default Slide;