import React from "react";
import { useLocation } from "react-router-dom";
const Header = () => {
        const { pathname } = useLocation();
        return(
            <div id="Header_wrapper">
  <header id="Header">
    <div id="Top_bar">
      <div className="container">
        <div className="column one">
          <div className="top_bar_left clearfix">
            <div className="logo">
              <a id="logo" href="index-erp.html" title="BeERP - BeTheme" data-height={60} data-padding={15}><img className="logo-main scale-with-grid" src="assets/images/erp.png" data-retina="assets/images/erp_retina.png" data-height={50} alt="erp" /><img className="logo-sticky scale-with-grid" src="assets/images/erp.png" data-retina="assets/images/erp_retina.png" data-height={50} alt="erp" /><img className="logo-mobile scale-with-grid" src="assets/images/erp.png" data-retina="assets/images/erp_retina.png" data-height={50} alt="erp" /><img className="logo-mobile-sticky scale-with-grid" src="assets/images/erp.png" data-retina="assets/images/erp_retina.png" data-height={50} alt="erp" /></a>
            </div>
            <div className="menu_wrapper">
              <nav id="menu">
                <ul id="menu-menu" className="menu menu-main">
                    <li className= {pathname == '/'? "current-menu-item":""}>
                        <a href="/"><span>Home</span></a>
                    </li>
                    <li className= {pathname == '/modules'? "current-menu-item":""}>
                        <a href="/modules"><span>Modules</span></a>
                    </li>
                    <li className= {pathname == '/ourcompany'? "current-menu-item":""}>
                        <a href="/ourcompany"><span>Our company</span></a>
                    </li>
                    <li className= {pathname == '/details'? "current-menu-item":""}>
                        <a href="/details"><span>Details</span></a>
                    </li>
                    <li className= {pathname == '/documentation'? "current-menu-item":""}>
                        <a href="/documentation"><span>Documentation</span></a>
                    </li>                                           
                </ul>
              </nav>
              <a className="responsive-menu-toggle" href="#"><i className="icon-menu-fine" /></a>
            </div>
            <div className="secondary_menu_wrapper" />
            <div className="banner_wrapper">
              <img src="assets/images/erp-header-banner.png" />
            </div>
            <div className="search_wrapper">
              <form method="get" id="searchform" action="#">
                <i className="icon_search icon-search-fine" /><a href="#" className="icon_close"><i className="icon-cancel-fine" /></a>
                <input type="text" className="field" name="s" placeholder="Enter your search" />
                <input type="submit" className="submit" defaultValue style={{display: 'none'}} />
              </form>
            </div>
          </div>
          <div className="top_bar_right">
            <div className="top_bar_right_wrapper">
              <a id="search_button" href="#"><i className="icon-search-fine" /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</div>

        );
    };
    export default Header;