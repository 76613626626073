import React from "react";
class Footer extends React.Component{
    render(){
        return(
            <React.Fragment>
                <footer id="Footer" className="clearfix">
            <div className="widgets_wrapper" style={{padding:'70px 0'}}>
                <div className="container">
                    <div className="column one-third">
                        <aside className="widget_text widget widget_custom_html">
                            <div className="textwidget custom-html-widget">
                                <h4>Find us here</h4>
                                <hr className="no_line" style={{margin:'0 auto 10px'}} />
                                <p>
                                    <a href="#"><i className="icon-facebook"></i></a><a href="#"><i className="icon-gplus"></i></a><a href="#"><i className="icon-twitter"></i></a><a href="#"><i className="icon-linkedin"></i></a>
                                </p>
                            </div>
                        </aside>
                    </div>
                    <div className="column one-third">
                        <aside className="widget_text widget widget_custom_html">
                            <div className="textwidget custom-html-widget">
                                <h4>Contact us</h4>
                                <hr className="no_line" style={{margin:'0 auto 10px'}} />
                                <p>
                                    Nullam viverra consectetuer
                                    <br /> quisque cursus et, porttitor risus.
                                </p>
                                <p>
                                    +61 (0) 3 8376 6284
                                    <br />
                                    <a href="#">noreply@envato.com</a>
                                </p>
                            </div>
                        </aside>
                    </div>
                    <div className="column one-third">
                        <aside className="widget_text widget widget_custom_html">
                            <div className="textwidget custom-html-widget">
                                <h4> Localization</h4>
                                <p>
                                    Envato
                                    <br /> Level 13, 2 Elizabeth
                                    <br /> Victoria 3000
                                    <br /> Australia
                                </p>
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
            <div className="footer_copy">
                <div className="container">
                    <div className="column one">
                        <a id="back_to_top" className="button button_js" href="#"><i className="icon-up-open-big"></i></a>
                        <div className="copyright">
                            &copy; 2018 BeERP - BeTheme. Muffin group - HTML by <a target="_blank" rel="nofollow" href="https://1.envato.market/9ZxXY">BeantownThemes</a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
            </React.Fragment>
        );
    }
}
export default Footer;