import React from 'react';

const Modules = () => {
      return (  
        <div id="Content">
          <div className="content_wrapper clearfix">
            <div className="sections_group">
              <div className="entry-content">
                <div className="section mcb-section bg-cover" style={{paddingTop: '125px', paddingBottom: '125px', backgroundImage: 'url(assets/images/erp-modules-header-bg.jpg)', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}>
                  <div className="section_wrapper mcb-section-inner">
                    <div className="wrap mcb-wrap one valign-top clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column three-fifth column_column column-margin-0px">
                          <div className="column_attr clearfix">
                            <h1>Advanced and easy
                              <br /> to implement resource
                              <br /> planning system</h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section mcb-section" style={{paddingTop: '100px', paddingBottom: '0px'}}>
                  <div className="section_wrapper mcb-section-inner">
                    <div className="wrap mcb-wrap one valign-top clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_column">
                          <div className="column_attr clearfix align_center">
                            <h6>BUY LICENSES</h6>
                            <h2>Core module with 7 additional modules
                              <br /> are ready for your needs</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section mcb-section" style={{paddingTop: '50px', paddingBottom: '50px'}}>
                  <div className="section_wrapper mcb-section-inner">
                    <div className="wrap mcb-wrap one erp-shadow erp-border valign-top clearfix" style={{padding: '70px 4% 20px'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one-fourth column_image">
                          <div className="image_frame image_item no_link scale-with-grid aligncenter no_border">
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp-offer-icon1.png" />
                            </div>
                          </div>
                        </div>
                        <div className="column mcb-column two-third column_column">
                          <div className="column_attr clearfix mobile_align_center">
                            <h2>Core Module</h2>
                            <hr className="no_line" style={{margin: '0 auto 20px'}} />
                            <p>
                              Curabitur sed iaculis dolor, non congue ligula. Maecenas imperdiet ante eget hendrerit posuere. Nunc urna libero, congue porta nibh a, semper feugiat sem. Sed auctor dui eleifend, scelerisque eros ut, pellentesque nibh. Nam lacinia suscipit accumsan.
                              Donec sodales, neque vitae rutrum convallis, nulla tortor pharetra odio, in varius ante ante sed nisi. Orci varius natoque penatibus et magnis dis parturient montes.
                            </p>
                          </div>
                        </div>
                        <div className="column mcb-column one column_divider">
                          <hr className="no_line" />
                        </div>
                        <div className="column mcb-column one-fourth column_placeholder">
                          <div className="placeholder">
                            &nbsp;
                          </div>
                        </div>
                        <div className="column mcb-column one-sixth column_column">
                          <div className="column_attr clearfix mobile_align_center">
                            <div className="image_frame image_item no_link scale-with-grid no_border">
                              <div className="image_wrapper">
                                <img className="scale-with-grid" src="assets/images/erp_modules_icon4.png" />
                              </div>
                            </div>
                            <hr className="no_line" style={{margin: '0 auto 15px'}} />
                            <h3 style={{fontSize: '20px', lineHeight: '28px'}}>Easy to
                              <br /> implement
                            </h3>
                          </div>
                        </div>
                        <div className="column mcb-column one-sixth column_column">
                          <div className="column_attr clearfix mobile_align_center">
                            <div className="image_frame image_item no_link scale-with-grid no_border">
                              <div className="image_wrapper">
                                <img className="scale-with-grid" src="assets/images/erp_modules_icon6.png" />
                              </div>
                            </div>
                            <hr className="no_line" style={{margin: '0 auto 15px'}} />
                            <h3 style={{fontSize: '20px', lineHeight: '28px'}}>Cloud
                              <br /> based
                            </h3>
                          </div>
                        </div>
                        <div className="column mcb-column one-sixth column_column">
                          <div className="column_attr clearfix mobile_align_center">
                            <div className="image_frame image_item no_link scale-with-grid no_border">
                              <div className="image_wrapper">
                                <img className="scale-with-grid" src="assets/images/erp_modules_icon7.png" />
                              </div>
                            </div>
                            <hr className="no_line" style={{margin: '0 auto 15px'}} />
                            <h3 style={{fontSize: '20px', lineHeight: '28px'}}>Real time
                              <br /> control
                            </h3>
                          </div>
                        </div>
                        <div className="column mcb-column one-sixth column_column">
                          <div className="column_attr clearfix mobile_align_center">
                            <div className="image_frame image_item no_link scale-with-grid no_border">
                              <div className="image_wrapper">
                                <img className="scale-with-grid" src="assets/images/erp_modules_icon5.png" />
                              </div>
                            </div>
                            <hr className="no_line" style={{margin: '0 auto 15px'}} />
                            <h3 style={{fontSize: '20px', lineHeight: '28px'}}>Lorem
                              <br /> ipsum dolor</h3>
                          </div>
                        </div>
                        <div className="column mcb-column one column_divider">
                          <hr className="no_line" />
                        </div>
                        <div className="column mcb-column one-fourth column_placeholder">
                          <div className="placeholder">
                            &nbsp;
                          </div>
                        </div>
                        <div className="column mcb-column one-third column_column">
                          <div className="column_attr clearfix">
                            <a className="button button_full_width button_size_3 button_js" href="details.html" target="_blank" style={{backgroundColor: '#eeeef6 ', color: '#000'}}><span className="button_label">View details</span></a>
                          </div>
                        </div>
                        <div className="column mcb-column one-third column_column">
                          <div className="column_attr clearfix">
                            <a className="button button_full_width button_size_3 button_js" href="details.html" target="_blank" style={{backgroundColor: '#5dab00 ', color: '#fff'}}><span className="button_label">Download</span></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section mcb-section" style={{paddingTop: '50px', paddingBottom: '50px'}}>
                  <div className="section_wrapper mcb-section-inner">
                    <div className="wrap mcb-wrap one erp-shadow erp-border valign-top clearfix" style={{padding: '70px 4% 20px'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one-fourth column_image">
                          <div className="image_frame image_item no_link scale-with-grid aligncenter no_border">
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp-offer-icon2.png" />
                            </div>
                          </div>
                        </div>
                        <div className="column mcb-column two-third column_column">
                          <div className="column_attr clearfix mobile_align_center">
                            <h2>Human Resources</h2>
                            <hr className="no_line" style={{margin: '0 auto 20px'}} />
                            <p>
                              Curabitur sed iaculis dolor, non congue ligula. Maecenas imperdiet ante eget hendrerit posuere. Nunc urna libero, congue porta nibh a, semper feugiat sem. Sed auctor dui eleifend, scelerisque eros ut, pellentesque nibh. Nam lacinia suscipit accumsan.
                              Donec sodales, neque vitae rutrum convallis, nulla tortor pharetra odio, in varius ante ante sed nisi. Orci varius natoque penatibus et magnis dis parturient montes.
                            </p>
                          </div>
                        </div>
                        <div className="column mcb-column one column_divider">
                          <hr className="no_line" />
                        </div>
                        <div className="column mcb-column one-fourth column_placeholder">
                          <div className="placeholder">
                            &nbsp;
                          </div>
                        </div>
                        <div className="column mcb-column one-sixth column_column">
                          <div className="column_attr clearfix mobile_align_center">
                            <div className="image_frame image_item no_link scale-with-grid no_border">
                              <div className="image_wrapper">
                                <img className="scale-with-grid" src="assets/images/erp_modules_icon4.png" />
                              </div>
                            </div>
                            <hr className="no_line" style={{margin: '0 auto 15px'}} />
                            <h3 style={{fontSize: '20px', lineHeight: '28px'}}>Easy to
                              <br /> implement
                            </h3>
                          </div>
                        </div>
                        <div className="column mcb-column one-sixth column_column">
                          <div className="column_attr clearfix mobile_align_center">
                            <div className="image_frame image_item no_link scale-with-grid no_border">
                              <div className="image_wrapper">
                                <img className="scale-with-grid" src="assets/images/erp_modules_icon6.png" />
                              </div>
                            </div>
                            <hr className="no_line" style={{margin: '0 auto 15px'}} />
                            <h3 style={{fontSize: '20px', lineHeight: '28px'}}>Cloud
                              <br /> based
                            </h3>
                          </div>
                        </div>
                        <div className="column mcb-column one-sixth column_column">
                          <div className="column_attr clearfix mobile_align_center">
                            <div className="image_frame image_item no_link scale-with-grid no_border">
                              <div className="image_wrapper">
                                <img className="scale-with-grid" src="assets/images/erp_modules_icon7.png" />
                              </div>
                            </div>
                            <hr className="no_line" style={{margin: '0 auto 15px'}} />
                            <h3 style={{fontSize: '20px', lineHeight: '28px'}}>Real time
                              <br /> control
                            </h3>
                          </div>
                        </div>
                        <div className="column mcb-column one-sixth column_column">
                          <div className="column_attr clearfix mobile_align_center">
                            <div className="image_frame image_item no_link scale-with-grid no_border">
                              <div className="image_wrapper">
                                <img className="scale-with-grid" src="assets/images/erp_modules_icon5.png" />
                              </div>
                            </div>
                            <hr className="no_line" style={{margin: '0 auto 15px'}} />
                            <h3 style={{fontSize: '20px', lineHeight: '28px'}}>Lorem
                              <br /> ipsum dolor</h3>
                          </div>
                        </div>
                        <div className="column mcb-column one column_divider">
                          <hr className="no_line" />
                        </div>
                        <div className="column mcb-column one-fourth column_placeholder">
                          <div className="placeholder">
                            &nbsp;
                          </div>
                        </div>
                        <div className="column mcb-column one-third column_column">
                          <div className="column_attr clearfix mobile_align_center">
                            <a className="button button_full_width button_size_3 button_js" href="details.html" target="_blank" style={{backgroundColor: '#eeeef6 ', color: '#000'}}><span className="button_label">View details</span></a>
                          </div>
                        </div>
                        <div className="column mcb-column one-third column_column">
                          <div className="column_attr clearfix mobile_align_center">
                            <a className="button button_full_width button_size_3 button_js" href="details.html" target="_blank" style={{backgroundColor: '#5dab00 ', color: '#fff'}}><span className="button_label">Download</span></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section mcb-section" style={{paddingTop: '50px', paddingBottom: '50px'}}>
                  <div className="section_wrapper mcb-section-inner">
                    <div className="wrap mcb-wrap one erp-shadow erp-border valign-top clearfix" style={{padding: '70px 4% 20px'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one-fourth column_image">
                          <div className="image_frame image_item no_link scale-with-grid aligncenter no_border">
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp-offer-icon4.png" />
                            </div>
                          </div>
                        </div>
                        <div className="column mcb-column two-third column_column">
                          <div className="column_attr clearfix mobile_align_center">
                            <h2>Accounting module</h2>
                            <hr className="no_line" style={{margin: '0 auto 20px'}} />
                            <p>
                              Curabitur sed iaculis dolor, non congue ligula. Maecenas imperdiet ante eget hendrerit posuere. Nunc urna libero, congue porta nibh a, semper feugiat sem. Sed auctor dui eleifend, scelerisque eros ut, pellentesque nibh. Nam lacinia suscipit accumsan.
                              Donec sodales, neque vitae rutrum convallis, nulla tortor pharetra odio, in varius ante ante sed nisi. Orci varius natoque penatibus et magnis dis parturient montes.
                            </p>
                          </div>
                        </div>
                        <div className="column mcb-column one column_divider">
                          <hr className="no_line" />
                        </div>
                        <div className="column mcb-column one-fourth column_placeholder">
                          <div className="placeholder">
                            &nbsp;
                          </div>
                        </div>
                        <div className="column mcb-column one-sixth column_column">
                          <div className="column_attr clearfix mobile_align_center">
                            <div className="image_frame image_item no_link scale-with-grid no_border">
                              <div className="image_wrapper">
                                <img className="scale-with-grid" src="assets/images/erp_modules_icon4.png" />
                              </div>
                            </div>
                            <hr className="no_line" style={{margin: '0 auto 15px'}} />
                            <h3 style={{fontSize: '20px', lineHeight: '28px'}}>Easy to
                              <br /> implement
                            </h3>
                          </div>
                        </div>
                        <div className="column mcb-column one-sixth column_column">
                          <div className="column_attr clearfix mobile_align_center">
                            <div className="image_frame image_item no_link scale-with-grid no_border">
                              <div className="image_wrapper">
                                <img className="scale-with-grid" src="assets/images/erp_modules_icon6.png" />
                              </div>
                            </div>
                            <hr className="no_line" style={{margin: '0 auto 15px'}} />
                            <h3 style={{fontSize: '20px', lineHeight: '28px'}}>Cloud
                              <br /> based
                            </h3>
                          </div>
                        </div>
                        <div className="column mcb-column one-sixth column_column">
                          <div className="column_attr clearfix mobile_align_center">
                            <div className="image_frame image_item no_link scale-with-grid no_border">
                              <div className="image_wrapper">
                                <img className="scale-with-grid" src="assets/images/erp_modules_icon7.png" />
                              </div>
                            </div>
                            <hr className="no_line" style={{margin: '0 auto 15px'}} />
                            <h3 style={{fontSize: '20px', lineHeight: '28px'}}>Real time
                              <br /> control
                            </h3>
                          </div>
                        </div>
                        <div className="column mcb-column one-sixth column_column">
                          <div className="column_attr clearfix mobile_align_center">
                            <div className="image_frame image_item no_link scale-with-grid no_border">
                              <div className="image_wrapper">
                                <img className="scale-with-grid" src="assets/images/erp_modules_icon5.png" />
                              </div>
                            </div>
                            <hr className="no_line" style={{margin: '0 auto 15px'}} />
                            <h3 style={{fontSize: '20px', lineHeight: '28px'}}>Lorem
                              <br /> ipsum dolor</h3>
                          </div>
                        </div>
                        <div className="column mcb-column one column_divider">
                          <hr className="no_line" />
                        </div>
                        <div className="column mcb-column one-fourth column_placeholder">
                          <div className="placeholder">
                            &nbsp;
                          </div>
                        </div>
                        <div className="column mcb-column one-third column_column">
                          <div className="column_attr clearfix">
                            <a className="button button_full_width button_size_3 button_js" href="details.html" target="_blank" style={{backgroundColor: '#eeeef6 ', color: '#000'}}><span className="button_label">View details</span></a>
                          </div>
                        </div>
                        <div className="column mcb-column one-third column_column">
                          <div className="column_attr clearfix">
                            <a className="button button_full_width button_size_3 button_js" href="details.html" target="_blank" style={{backgroundColor: '#5dab00 ', color: '#fff'}}><span className="button_label">Download</span></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section mcb-section" style={{paddingTop: '50px', paddingBottom: '50px'}}>
                  <div className="section_wrapper mcb-section-inner">
                    <div className="wrap mcb-wrap one erp-shadow erp-border valign-top clearfix" style={{padding: '70px 4% 20px'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one-fourth column_image">
                          <div className="image_frame image_item no_link scale-with-grid aligncenter no_border">
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp-offer-icon5.png" />
                            </div>
                          </div>
                        </div>
                        <div className="column mcb-column two-third column_column">
                          <div className="column_attr clearfix mobile_align_center">
                            <h2>Store &amp; Marketing</h2>
                            <hr className="no_line" style={{margin: '0 auto 20px'}} />
                            <p>
                              Curabitur sed iaculis dolor, non congue ligula. Maecenas imperdiet ante eget hendrerit posuere. Nunc urna libero, congue porta nibh a, semper feugiat sem. Sed auctor dui eleifend, scelerisque eros ut, pellentesque nibh. Nam lacinia suscipit accumsan.
                              Donec sodales, neque vitae rutrum convallis, nulla tortor pharetra odio, in varius ante ante sed nisi. Orci varius natoque penatibus et magnis dis parturient montes.
                            </p>
                          </div>
                        </div>
                        <div className="column mcb-column one column_divider">
                          <hr className="no_line" />
                        </div>
                        <div className="column mcb-column one-fourth column_placeholder">
                          <div className="placeholder">
                            &nbsp;
                          </div>
                        </div>
                        <div className="column mcb-column one-sixth column_column">
                          <div className="column_attr clearfix mobile_align_center">
                            <div className="image_frame image_item no_link scale-with-grid no_border">
                              <div className="image_wrapper">
                                <img className="scale-with-grid" src="assets/images/erp_modules_icon4.png" />
                              </div>
                            </div>
                            <hr className="no_line" style={{margin: '0 auto 15px'}} />
                            <h3 style={{fontSize: '20px', lineHeight: '28px'}}>Easy to
                              <br /> implement
                            </h3>
                          </div>
                        </div>
                        <div className="column mcb-column one-sixth column_column">
                          <div className="column_attr clearfix mobile_align_center">
                            <div className="image_frame image_item no_link scale-with-grid no_border">
                              <div className="image_wrapper">
                                <img className="scale-with-grid" src="assets/images/erp_modules_icon6.png" />
                              </div>
                            </div>
                            <hr className="no_line" style={{margin: '0 auto 15px'}} />
                            <h3 style={{fontSize: '20px', lineHeight: '28px'}}>Cloud
                              <br /> based
                            </h3>
                          </div>
                        </div>
                        <div className="column mcb-column one-sixth column_column">
                          <div className="column_attr clearfix mobile_align_center">
                            <div className="image_frame image_item no_link scale-with-grid no_border">
                              <div className="image_wrapper">
                                <img className="scale-with-grid" src="assets/images/erp_modules_icon7.png" />
                              </div>
                            </div>
                            <hr className="no_line" style={{margin: '0 auto 15px'}} />
                            <h3 style={{fontSize: '20px', lineHeight: '28px'}}>Real time
                              <br /> control
                            </h3>
                          </div>
                        </div>
                        <div className="column mcb-column one-sixth column_column">
                          <div className="column_attr clearfix mobile_align_center">
                            <div className="image_frame image_item no_link scale-with-grid no_border">
                              <div className="image_wrapper">
                                <img className="scale-with-grid" src="assets/images/erp_modules_icon5.png" />
                              </div>
                            </div>
                            <hr className="no_line" style={{margin: '0 auto 15px'}} />
                            <h3 style={{fontSize: '20px', lineHeight: '28px'}}>Lorem
                              <br /> ipsum dolor</h3>
                          </div>
                        </div>
                        <div className="column mcb-column one column_divider">
                          <hr className="no_line" />
                        </div>
                        <div className="column mcb-column one-fourth column_placeholder">
                          <div className="placeholder">
                            &nbsp;
                          </div>
                        </div>
                        <div className="column mcb-column one-third column_column">
                          <div className="column_attr clearfix">
                            <a className="button button_full_width button_size_3 button_js" href="details.html" target="_blank" style={{backgroundColor: '#eeeef6 ', color: '#000'}}><span className="button_label">View details</span></a>
                          </div>
                        </div>
                        <div className="column mcb-column one-third column_column">
                          <div className="column_attr clearfix">
                            <a className="button button_full_width button_size_3 button_js" href="details.html" target="_blank" style={{backgroundColor: '#5dab00 ', color: '#fff'}}><span className="button_label">Download</span></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section mcb-section" style={{paddingTop: '50px', paddingBottom: '50px'}}>
                  <div className="section_wrapper mcb-section-inner">
                    <div className="wrap mcb-wrap one-second valign-top clearfix" style={{padding: '0 2%'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_column erp-shadow erp-border">
                          <div className="column_attr clearfix align_center" style={{padding: '50px'}}>
                            <div className="image_frame image_item no_link scale-with-grid aligncenter no_border">
                              <div className="image_wrapper">
                                <img className="scale-with-grid" src="assets/images/erp-offer-icon6.png" />
                              </div>
                            </div>
                            <h2>Inventory</h2>
                            <hr className="no_line" style={{margin: '0 auto 20px'}} />
                            <p>
                              Curabitur sed iaculis dolor, non congue ligula. Maecenas imperdiet ante eget hendrerit posuere. Nunc urna libero, congue porta nibh a, semper feugiat sem. Sed auctor dui eleifend, scelerisque eros ut, pellentesque nibh. Nam lacinia suscipit accumsan.
                              Donec sodales, neque vitae rutrum convallis, nulla tortor pharetra odio, in varius ante ante sed nisi.
                            </p>
                            <hr className="no_line" style={{margin: '0 auto 10px'}} />
                            <a className="button button_full_width button_size_3 button_js" href="details.html" target="_blank" style={{backgroundColor: '#eeeef6 ', color: '#000'}}><span className="button_label">View details</span></a>
                            <a className="button button_full_width button_size_3 button_js" href="details.html" target="_blank" style={{backgroundColor: '#5dab00 ', color: '#fff'}}><span className="button_label">Download</span></a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-second valign-top clearfix" style={{padding: '0 2%'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_column erp-shadow erp-border">
                          <div className="column_attr clearfix align_center" style={{padding: '50px'}}>
                            <div className="image_frame image_item no_link scale-with-grid aligncenter no_border">
                              <div className="image_wrapper">
                                <img className="scale-with-grid" src="assets/images/erp-offer-icon7.png" />
                              </div>
                            </div>
                            <h2>CRM</h2>
                            <hr className="no_line" style={{margin: '0 auto 20px'}} />
                            <p>
                              Curabitur sed iaculis dolor, non congue ligula. Maecenas imperdiet ante eget hendrerit posuere. Nunc urna libero, congue porta nibh a, semper feugiat sem. Sed auctor dui eleifend, scelerisque eros ut, pellentesque nibh. Nam lacinia suscipit accumsan.
                              Donec sodales, neque vitae rutrum convallis, nulla tortor pharetra odio, in varius ante ante sed nisi.
                            </p>
                            <hr className="no_line" style={{margin: '0 auto 10px'}} />
                            <a className="button button_full_width button_size_3 button_js" href="details.html" target="_blank" style={{backgroundColor: '#eeeef6 ', color: '#000'}}><span className="button_label">View details</span></a>
                            <a className="button button_full_width button_size_3 button_js" href="details.html" target="_blank" style={{backgroundColor: '#5dab00 ', color: '#fff'}}><span className="button_label">Download</span></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section mcb-section equal-height-wrap" style={{paddingTop: '50px', paddingBottom: '50px'}}>
                  <div className="section_wrapper mcb-section-inner">
                    <div className="wrap mcb-wrap one-fourth valign-middle clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_image">
                          <div className="image_frame image_item no_link scale-with-grid no_border">
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp-home-features-icon1.png" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-fourth valign-middle clearfix" style={{padding: '0 30px 0 0'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_column">
                          <div className="column_attr clearfix">
                            <h3>Integration</h3>
                            <p>
                              Ut ultricies imperdiet sodales. Aliquam fringilla aliquam ex sit amet elementum. Proin bibendum sollicitudin feugiat.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-fourth valign-middle clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_image">
                          <div className="image_frame image_item no_link scale-with-grid no_border">
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp-home-features-icon2.png" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-fourth valign-middle clearfix" style={{padding: '0 30px 0 0'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_column">
                          <div className="column_attr clearfix">
                            <h3>Free training</h3>
                            <p>
                              Ut ultricies imperdiet sodales. Aliquam fringilla aliquam ex sit amet elementum. Proin bibendum sollicitudin feugiat.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-fourth valign-middle clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_image">
                          <div className="image_frame image_item no_link scale-with-grid no_border">
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp-home-features-icon3.png" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-fourth valign-middle clearfix" style={{padding: '0 30px 0 0'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_column">
                          <div className="column_attr clearfix">
                            <h3>Free updates</h3>
                            <p>
                              Ut ultricies imperdiet sodales. Aliquam fringilla aliquam ex sit amet elementum. Proin bibendum sollicitudin feugiat.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-fourth valign-middle clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_image">
                          <div className="image_frame image_item no_link scale-with-grid no_border">
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp-home-features-icon4.png" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-fourth valign-middle clearfix" style={{padding: '0 30px 0 0'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_column">
                          <div className="column_attr clearfix">
                            <h3>Cloud based</h3>
                            <p>
                              Ut ultricies imperdiet sodales. Aliquam fringilla aliquam ex sit amet elementum. Proin bibendum sollicitudin feugiat.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section mcb-section" style={{paddingTop: '50px', paddingBottom: '100px'}}>
                  <div className="section_wrapper mcb-section-inner">
                    <div className="wrap mcb-wrap one-second valign-top clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column five-sixth column_image">
                          <div className="image_frame image_item no_link scale-with-grid no_border">
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp-home-phoen-icon.png" />
                            </div>
                          </div>
                        </div>
                        <div className="column mcb-column one column_column">
                          <div className="column_attr clearfix">
                            <h6>CONTACT WITH US</h6>
                            <h2>Order free training
                              <br /> and presentation</h2>
                            <hr className="no_line" style={{margin: '0 auto 20px'}} />
                            <h4>+22 94 485 223</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-second valign-top clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column five-sixth column_image">
                          <div className="image_frame image_item no_link scale-with-grid no_border">
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp-cloud-icon.png" />
                            </div>
                          </div>
                        </div>
                        <div className="column mcb-column one column_column">
                          <div className="column_attr clearfix">
                            <h6>DOWNLOAD</h6>
                            <h2>Start free 30day trial
                              <br /> with 3 example modules</h2>
                            <hr className="no_line" style={{margin: '0 auto 20px'}} />
                            <div className="image_frame image_item scale-with-grid no_border hover-disable">
                              <div className="image_wrapper">
                                <a href="modules.html">
                                  <div className="mask" />
                                  <img className="scale-with-grid" src="assets/images/erp-slider-app-store-button.png" />
                                </a>
                                <div className="image_links">
                                  <a href="modules.html" className="link"><i className="icon-link" /></a>
                                </div>
                              </div>
                            </div>
                            <hr className="no_line" style={{margin: '0 auto 5px'}} />
                            <p style={{opacity: '.5'}}>
                              iOS 9.3+
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };

    export default Modules;