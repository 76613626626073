import React from 'react';
const Documetation = () => {
      return (  
        <div id="Content">
          <div className="content_wrapper clearfix">
            <div className="sections_group">
              <div className="entry-content">
                <div className="section mcb-section" id="one" style={{paddingTop: '100px', paddingBottom: '50px', backgroundColor: '#fff'}}>
                  <div className="section_wrapper mcb-section-inner">
                    <div className="wrap mcb-wrap one-fourth valign-top clearfix" style={{padding: '0 0 50px 0'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_column erp-border erp-shadow">
                          <div className="column_attr clearfix" style={{padding: '30px 5%'}}>
                            <ul style={{listStyle: 'none', lineHeight: '35px'}}>
                              <li>
                                <a className="scroll" href="#one">Getting Started</a>
                              </li>
                              <li>
                                <a className="scroll" href="#two">The interface</a>
                              </li>
                              <li>
                                <a className="scroll" href="#three">Layer Basics</a>
                              </li>
                              <li>
                                <a className="scroll" href="#four">Shapes</a>
                              </li>
                              <li>
                                <a className="scroll" href="#five">Messages</a>
                              </li>
                              <li>
                                <a className="scroll" href="#six">Profile</a>
                              </li>
                              <li>
                                <a className="scroll" href="#four">Grouping Layers</a>
                              </li>
                              <li>
                                <a className="scroll" href="#five">Styling</a>
                              </li>
                              <li>
                                <a className="scroll" href="#six">Symbols</a>
                              </li>
                              <li>
                                <a className="scroll" href="#four">Canvas</a>
                              </li>
                              <li>
                                <a className="scroll" href="#five">Libraries</a>
                              </li>
                              <li>
                                <a className="scroll" href="#six">Symbols</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap three-fourth valign-top clearfix" style={{padding: '0 0 0 7%'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_column">
                          <div className="column_attr clearfix">
                            <h2>Lorem ipsum dolor sit amet</h2>
                            <hr className="no_line" style={{margin: '0 auto 30px'}} />
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </p>
                            <p>
                              Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus
                              error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem
                              quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur,
                              adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.
                            </p>
                          </div>
                        </div>
                        <div className="column mcb-column one column_image">
                          <div className="image_frame image_item no_link scale-with-grid no_border">
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp-home-erp-panel.jpg" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section mcb-section" id="six" style={{paddingTop: '50px', paddingBottom: '50px', backgroundColor: '#fff'}}>
                  <div className="section_wrapper mcb-section-inner">
                    <div className="wrap mcb-wrap one-fourth valign-top clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_placeholder">
                          <div className="placeholder">
                            &nbsp;
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap three-fourth valign-top clearfix" style={{padding: '0 0 0 7%'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_column">
                          <div className="column_attr clearfix">
                            <div className="image_frame image_item no_link scale-with-grid no_border" style={{marginBottom: '15px'}}>
                              <div className="image_wrapper">
                                <img className="scale-with-grid" src="assets/images/app3-features-small-line.png" />
                              </div>
                            </div>
                            <h2>Quantum petron vari porto</h2>
                            <hr className="no_line" style={{margin: '0 auto 30px'}} />
                          </div>
                        </div>
                        <div className="column mcb-column one column_column column-margin-10px">
                          <div className="column_attr clearfix">
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex e
                            </p>
                          </div>
                        </div>
                        <div className="column mcb-column one column_column column-margin-10px">
                          <div className="column_attr clearfix">
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex e
                            </p>
                          </div>
                        </div>
                        <div className="column mcb-column one column_divider">
                          <hr className="no_line" />
                        </div>
                        <div className="column mcb-column one column_column column-margin-10px">
                          <div className="column_attr clearfix">
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex e
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section mcb-section" id="three" style={{paddingTop: '0px', paddingBottom: '0px', backgroundColor: '#fff'}}>
                  <div className="section_wrapper mcb-section-inner">
                    <div className="wrap mcb-wrap one-fourth valign-top clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_placeholder">
                          <div className="placeholder">
                            &nbsp;
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap three-fourth valign-top clearfix" style={{padding: '0 0 0 7%'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column five-sixth column_image">
                          <div className="image_frame image_item no_link scale-with-grid aligncenter no_border">
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp-home-smartphone.jpg" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section mcb-section" id="six" style={{paddingTop: '50px', paddingBottom: '50px', backgroundColor: '#fff'}}>
                  <div className="section_wrapper mcb-section-inner">
                    <div className="wrap mcb-wrap one-fourth valign-top clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_placeholder">
                          <div className="placeholder">
                            &nbsp;
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap three-fourth valign-top clearfix" style={{padding: '0 0 0 7%'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_column">
                          <div className="column_attr clearfix">
                            <div className="image_frame image_item no_link scale-with-grid no_border" style={{marginBottom: '15px'}}>
                              <div className="image_wrapper">
                                <img className="scale-with-grid" src="assets/images/app3-features-small-line.png" />
                              </div>
                            </div>
                            <h2>Lorem ipsum dolor sit amet</h2>
                            <hr className="no_line" style={{margin: '0 auto 30px'}} />
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </p>
                            <p>
                              Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus
                              error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem
                              quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur,
                              adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.
                            </p>
                            <hr className="no_line" style={{margin: '0 auto 40px'}} />
                            <div className="idea_box">
                              <div className="icon">
                                <i className="icon-lamp" />
                              </div>
                              <div className="desc">
                                Raesent nec magna ac ped auris suscipit mam quis.
                              </div>
                            </div>
                            <hr className="no_line" style={{margin: '0 auto 40px'}} />
                          </div>
                        </div>
                        <div className="column mcb-column one column_tabs">
                          <div className="jq-tabs tabs_wrapper tabs_horizontal">
                            <ul>
                              <li>
                                <a href="#tab-5b9619df609b4-1">Lorem ipsum</a>
                              </li>
                              <li>
                                <a href="#tab-5b9619df609b4-2">Neque porro</a>
                              </li>
                              <li>
                                <a href="#tab-5b9619df609b4-3">Duis aute irure dolor</a>
                              </li>
                            </ul>
                            <div id="tab-5b9619df609b4-1" style={{padding: '40px 20px'}}>
                              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                              <br />
                              <br /> Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim
                              id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae
                              vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam
                              est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.
                            </div>
                            <div id="tab-5b9619df609b4-2" style={{padding: '40px 20px'}}>
                              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </div>
                            <div id="tab-5b9619df609b4-3" style={{padding: '40px 20px'}}>
                              Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus
                              error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem
                              quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur,
                              adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section mcb-section" id="five" style={{paddingTop: '0px', paddingBottom: '100px', backgroundColor: '#fff'}}>
                  <div className="section_wrapper mcb-section-inner">
                    <div className="wrap mcb-wrap one-fourth valign-top clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_placeholder">
                          <div className="placeholder">
                            &nbsp;
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap three-fourth valign-top clearfix" style={{padding: '0 0 0 7%'}}>
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_column">
                          <div className="column_attr clearfix">
                            <div className="image_frame image_item no_link scale-with-grid no_border" style={{marginBottom: '15px'}}>
                              <div className="image_wrapper">
                                <img className="scale-with-grid" src="assets/images/app3-features-small-line.png" />
                              </div>
                            </div>
                            <h2>Lorem ipsum dolor sit amet</h2>
                            <hr className="no_line" style={{margin: '0 auto 30px'}} />
                            <pre>/* CallingMethodsInSameClass.java{"\n"} *{"\n"} * illustrates how to call static methods a class{"\n"} * from a method in the same class{"\n"} */{"\n"}{"\n"}public class CallingMethodsInSameClass{"\n"}{"{"}{"\n"}{"\t"}public static void main(String[] args) {"{"}{"\n"}{"\t"}{"\t"}printOne();{"\n"}{"\t"}{"\t"}printOne();{"\n"}{"\t"}{"\t"}printTwo();{"\n"}{"\t"}{"}"}{"\n"}{"\n"}{"\t"}public static void printOne() {"{"}{"\n"}{"\t"}{"\t"}System.out.println("Hello World");{"\n"}{"\t"}{"}"}{"\n"}{"\n"}{"\t"}public static void printTwo() {"{"}{"\n"}{"\t"}{"\t"}printOne();{"\n"}{"\t"}{"\t"}printOne();{"\n"}{"\t"}{"}"}{"\n"}{"}"}{"\n"}</pre>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section mcb-section" style={{paddingTop: '0px', paddingBottom: '100px'}}>
                  <div className="section_wrapper mcb-section-inner">
                    <div className="wrap mcb-wrap one-second valign-top clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column five-sixth column_image">
                          <div className="image_frame image_item no_link scale-with-grid no_border">
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp-home-phoen-icon.png" />
                            </div>
                          </div>
                        </div>
                        <div className="column mcb-column one column_column">
                          <div className="column_attr clearfix">
                            <h6>CONTACT WITH US</h6>
                            <h2>Order free training
                              <br /> and presentation</h2>
                            <hr className="no_line" style={{margin: '0 auto 20px'}} />
                            <h4>+22 94 485 223</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap mcb-wrap one-second valign-top clearfix">
                      <div className="mcb-wrap-inner">
                        <div className="column mcb-column five-sixth column_image">
                          <div className="image_frame image_item no_link scale-with-grid no_border">
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src="assets/images/erp-cloud-icon.png" />
                            </div>
                          </div>
                        </div>
                        <div className="column mcb-column one column_column">
                          <div className="column_attr clearfix">
                            <h6>DOWNLOAD</h6>
                            <h2>Start free 30day trial
                              <br /> with 3 example modules</h2>
                            <hr className="no_line" style={{margin: '0 auto 20px'}} />
                            <div className="image_frame image_item scale-with-grid no_border hover-disable">
                              <div className="image_wrapper">
                                <a href="modules.html">
                                  <div className="mask" />
                                  <img className="scale-with-grid" src="assets/images/erp-slider-app-store-button.png" />
                                </a>
                                <div className="image_links">
                                  <a href="modules.html" className="link"><i className="icon-link" /></a>
                                </div>
                              </div>
                            </div>
                            <hr className="no_line" style={{margin: '0 auto 5px'}} />
                            <p style={{opacity: '.5'}}>
                              iOS 9.3+
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };

    export default Documetation;